@import url('https://fonts.googleapis.com/css2?family=Alegreya&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alegreya&family=Ubuntu:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');


body {
  margin: 0px;
  padding: 0px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}




.editor-container {
  background: #fff;
  margin: 20px auto 20px auto;
  border-radius: 2px;
  max-width: 600px;
  color: #000;
  position: relative;
  line-height: 20px;
  font-weight: 400;
  text-align: left;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.editor-input {
  min-height: 150px;
  resize: none;
  font-size: 15px;
  caret-color: rgb(5, 5, 5);
  position: relative;
  tab-size: 1;
  outline: 0;
  padding: 15px 10px;
  caret-color: #444;
}

.editor-placeholder {
  color: #999;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  top: 15px;
  left: 10px;
  font-size: 15px;
  user-select: none;
  display: inline-block;
  pointer-events: none;
}

.editor-paragraph {
  margin: 0 0 4px 0;
  position: relative;
}

.tree-view-output {
  display: block;
  background: #222;
  color: #fff;
  padding: 5px;
  font-size: 12px;
  white-space: pre-wrap;
  margin: 1px auto 10px auto;
  max-height: 250px;
  position: relative;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
  line-height: 14px;
}

pre {
  line-height: 1.1;
  background: #222;
  color: #fff;
  margin: 0;
  padding: 0px;
  font-size: 12px;
  overflow: auto;
  white-space: pre-wrap;
  max-height: 180px;
  
}



pre::-webkit-scrollbar {
  background: transparent;
  width: 7px;
  
}

pre::-webkit-scrollbar-thumb {
  background: #999;
  border-radius: 10px;

}

.debug-timetravel-panel {
  overflow: hidden;
  padding: 0 0 10px 0;
  margin: auto;
  display: flex;
}

.debug-timetravel-panel-slider {
  padding: 0;
  flex: 8;
}

.debug-timetravel-panel-button {
  padding: 0;
  border: 0;
  background: none;
  flex: 1;
  color: #fff;
  font-size: 12px;
}

.debug-timetravel-panel-button:hover {
  text-decoration: underline;
}

.debug-timetravel-button {
  border: 0;
  padding: 0;
  font-size: 12px;
  top: 10px;
  right: 15px;
  position: absolute;
  background: none;
  color: #fff;
}

.debug-timetravel-button:hover {
  text-decoration: underline;
}


.test-recorder-toolbar {
  display: flex;
}

.test-recorder-button {
  position: relative;
  display: block;
  width: 32px;
  height: 32px;
  font-size: 10px;
  padding: 6px 6px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  outline: none;
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.4);
  background-color: #222;
  transition: box-shadow 50ms ease-out;
}

.test-recorder-button:active {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.4);
}

.test-recorder-button + .test-recorder-button {
  margin-left: 4px;
}

.test-recorder-button::after {
  content: '';
  position: absolute;
  top: 8px;
  right: 8px;
  bottom: 8px;
  left: 8px;
  display: block;
  background-size: contain;
  filter: invert(1);
}





.emoji {
  color: transparent;
  background-size: 16px 16px;
  background-position: center;
  background-repeat: no-repeat;
  vertical-align: middle;
  margin: 0 -1px;
}

.emoji-inner {
  padding: 0 0.15em;
}

.emoji-inner::selection {
  color: transparent;
  background-color: rgba(150, 150, 150, 0.4);
}

.emoji-inner::moz-selection {
  color: transparent;
  background-color: rgba(150, 150, 150, 0.4);
}

.emoji.happysmile {
  background-image: url(/public/icons/1F642.png);
}





.antiform {
  margin: 0;
  margin-left:3px;
  border-left: 1px;
  border-color: #E65634;

}


.antiform-field {
 color:hsl(11, 78%, 55%)
}

.antiform-ref {
  font-size: 20px;
}

.antiform-cat  {
  font-size: 20px;
}
